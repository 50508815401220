import React from 'react';
import PropTypes from 'prop-types';
import {Paper} from "@material-ui/core";
import {SelectValidator, ValidatorForm} from "react-material-ui-form-validator";
import * as Actions from "../../redux/actions";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {withLocalize} from "react-localize-redux";
import LoadingButton from "../../utils/LoadingButton";
import Typography from "@material-ui/core/Typography/Typography";


const mapStateToProps = (state, props) => {
    return {
        account: state.account,
        settings: state.settings,
        regions: state.regions,
        openingConnectionTimePreset: state.openingConnectionTimePreset,
        ticketConnection: state.ticketConnection
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTimePreset: (value) => {
            dispatch(Actions.http.connection.openings.timePreset(value));
        },
        reserveTicket: (value) => {
            dispatch(Actions.http.connection.tickets.post(value));
        }
    }
};

class ReserveComplete extends React.Component {
    render() {
        const {translate} = this.props;
        console.log(this.props.timeData)
        return (
            <div className={"reserve-complete"}>
                <Paper className={"paper wide-paper"}>
                    <div className={"reserve-complte-title"}>

                    </div>
                    <div className={"reserve-complete-content"}>
                        <div className={"reserve-subject-cell"}>
                            <Typography variant="subtitle2" color={"textSecondary"}>
                                {translate("reserve_user")}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {this.props.account.payload.account_display_name}
                            </Typography>
                        </div>
                        <div className={"reserve-subject-cell"}>
                            <Typography variant="subtitle2" color={"textSecondary"}>
                                {translate("region")}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {this.props.selectData.item.region.region_name}
                            </Typography>
                        </div>

                        <div className={"reserve-subject-cell"}>
                            <Typography variant="subtitle2" color={"textSecondary"}>
                                {translate("area")}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {this.props.selectData.item.area.area_name}
                            </Typography>
                        </div>
                        <div className={"reserve-subject-cell"}>
                            <Typography variant="subtitle2" color={"textSecondary"}>
                                {translate("item")}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {this.props.selectData.item.item_name}
                            </Typography>
                        </div>

                        <div className={"reserve-subject-cell"}>
                            <Typography variant="subtitle2" color={"textSecondary"}>
                                {translate("reserve_count")}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {this.props.countData}
                            </Typography>
                        </div>

                        <div className={"reserve-subject-cell"}>
                            <Typography variant="subtitle2" color={"textSecondary"}>
                                {translate("reserve_date_and_time")}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {this.props.timeData.startDateMoment.format(`YYYY-MM-DD`)} {this.props.timeData.startTimeValue} ~ {this.props.timeData.endDateMoment.format(`YYYY-MM-DD`)} {this.props.timeData.endTimeValue === "00:00:00" ? "24:00:00": this.props.timeData.endTimeValue}
                            </Typography>
                        </div>
                    </div>

                    <LoadingButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            this.props.history.push("/")
                        }}
                    >
                        {translate("back_to_menu")}
                    </LoadingButton>
                </Paper>
            </div>
        );
    }
}

ReserveComplete.defaultProps = {};

ReserveComplete.propTypes = {
    completeData: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(ReserveComplete)));