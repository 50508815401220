import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize, Translate} from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {Icon, Paper, Typography} from "@material-ui/core";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import log from "../../utils/logger";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LoadingButton from "../../utils/LoadingButton";
import connect from "react-redux/es/connect/connect";
import moment from "moment";
import DatePickerDialog from "../../utils/DatePickerDialog";
import * as PropTypes from "prop-types";
import * as Actions from "../../redux/actions";
import Stepper from "@material-ui/core/Stepper/Stepper";
import StepLabel from "@material-ui/core/es/StepLabel/StepLabel";
import Step from "@material-ui/core/es/Step/Step";
import StepContent from "@material-ui/core/StepContent/StepContent";
import StepIcon from "@material-ui/core/StepIcon/StepIcon";
import SvgIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Button from "@material-ui/core/Button/Button";
import Input from "@material-ui/core/Input/Input";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Chip from "@material-ui/core/Chip/Chip";
import Timeline from "react-visjs-timeline";
import Grid from "@material-ui/core/Grid";
import * as Helper from "../../utils/Helper";

const mapStateToProps = (state, props) => {
    return {
        account: state.account,
        settings: state.settings,
        regions: state.regions,
        openingConnectionTimePreset: state.openingConnectionTimePreset,
        ticketConnection: state.ticketConnection
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTimePreset: (value) => {
            dispatch(Actions.http.connection.openings.timePreset(value));
        },
        reserveTicket: (value) => {
            dispatch(Actions.http.connection.tickets.post(value));
        }
    }
};

class ReserveInput extends Component {
    constructor(props) {
        super(props);

        let questionnairesAnswerData = {};
        let questionnairesData = [];
        if (props.selectData.item.questionnaires && props.selectData.item.questionnaires.length !== 0) {
            questionnairesData = props.selectData.item.questionnaires;
        }
        questionnairesData.forEach((value, index) => {
            switch (value.item_questionnaire_type_id) {
                case "1":
                    questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`] = undefined;
                    break;
                case "2":
                    questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`] = [];
                    break;
                case "3":
                    questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`] = "";
                    break;
                default:
                    questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`] = "";
                    break;
            }
        });

        this.state = {

            canOverDay: props.settings.payload.use_multi_day_reservation,

            accountData: props.account,
            searchData: props.searchData,
            selectData: props.selectData,
            opening_requiring_count: 1,
            loading: false,
            activeStep: 0,
            //検索済のdateMoment
            startSearchDateMoment: moment(props.searchData.date_start),
            endSearchDateMoment: moment(props.searchData.date_end),

            //選択用DateMoment
            startDateMoment: moment(props.searchData.date_start).startOf("day"),
            endDateMoment: moment(props.searchData.date_end).endOf("day"),

            startTimeValue: "",
            endTimeValue: "",

            questionnairesData,
            questionnairesAnswerData,

            start: moment(props.searchData.date_start).isBefore(moment()) ? moment() : moment(props.searchData.date_start).startOf("day"),
            end: moment(props.searchData.date_end).endOf("day"),
            min: moment(props.searchData.date_start).isBefore(moment()) ? moment() : moment(props.searchData.date_start).startOf("day"),
            max: moment(props.searchData.date_end).endOf("day"),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            timePresetLoading: nextProps.openingConnectionTimePreset.meta.fetch,
            ticketLoading: nextProps.ticketConnection.meta.fetch
        });

        if (nextProps.ticketConnection !== this.props.ticketConnection) {
            if (nextProps.ticketConnection.meta.status === Actions.statusEnum.SUCCESS) {
                this.props.handleNext();
            }
        }

        if (nextProps.openings !== this.props.openings) {
            this.setState({
                openings: nextProps.openings.payload,
            })
        }

        if (nextProps.searchData !== this.props.searchData) {
            this.setState({
                startDateMoment: moment(nextProps.searchData.date_start),
                endDateMoment: moment(nextProps.searchData.date_end),
                startSearchDateMoment: moment(nextProps.searchData.date_start),
                endSearchDateMoment: moment(nextProps.searchData.date_end),
            })
        }


        if (nextProps.openingConnectionTimePreset !== this.props.openingConnectionTimePreset) {
            if (nextProps.openingConnectionTimePreset.meta.status === Actions.statusEnum.SUCCESS) {
                this.setState({
                    timePresetData: nextProps.openingConnectionTimePreset.payload.result
                }, () => {
                    this.handleNext();
                });
            }
        }
    }

    /**
     * componentDidUpdateをokonau
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

    }

    /**
     * タイムプリセットを入力し内容で取得
     */
    getTimePreset = () => {
        this.setState({
            timePresetLoading: true
        });
        let fieldValue = {};
        fieldValue.account_id = this.state.accountData.payload.account_id;
        fieldValue.area_id = this.state.searchData.area_id;
        fieldValue.opening_requiring_count = this.state.opening_requiring_count;
        fieldValue.date_start = this.state.searchData.date_start;
        fieldValue.date_end = this.state.searchData.date_end;
        fieldValue.item_id = this.state.selectData.item.item_id;
        this.props.getTimePreset(fieldValue);
    };


    handleScroll = (activeStep) => {

        log.debug(activeStep)
        switch (activeStep) {
            case 0:
                window.scrollTo(null, 196);
                break;
            case 1:
                window.scrollTo(null, 252);
                break;
            case 2:
                window.scrollTo(null, 4444);
                break;
            default:
                window.scrollTo(null, 196);
                break;
        }

    }


    handleNext = (value) => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }), () => {
            this.handleScroll(this.state.activeStep);
        });
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }), () => {
            this.handleScroll(this.state.activeStep);
        });
    };

    handleChange = name => event => {
        log.debug(name);
        log.debug(event.target.value);
        this.setState({
            [name]: event.target.value,
        });
    };

    handleChangeAnswer = name => event => {
        log.debug(name);
        log.debug(event);
        let questionnairesAnswerData = this.state.questionnairesAnswerData;
        questionnairesAnswerData[name] = event.target.value;
        this.setState({
            questionnairesAnswerData
        });
        log.debug(questionnairesAnswerData)
    }


    //一つ変更した際に他も追随
    rangeChangeHandler = (props) => {
        this.setState({
            start: props.start,
            end: props.end
        })
    };


    /**
     * 開始日の変更
     * @param date
     * @param dateString
     */
    onChangeStartDate = (date, dateString) => {
        //現在時刻より指定した日の始まり日が前のときは、今の値をtimelineに反映
        if (date.startOf("day").isSameOrBefore(moment())) {
            this.setState({
                start: moment(),
                min: moment()
            })
        }
        this.setState({
            startDateMoment: date,
            startTimeValue: "",
            endTimeValue: "",
        });
        //終了日との兼ね合い
        if (date.isSameOrBefore(this.state.endDateMoment, "day")) {
            //正常
        } else {
            this.setState({
                endDateMoment: date
            });
        }
    };


    /**
     * 終了日の変更
     * @param date
     * @param dateString
     */
    onChangeEndDate = (date, dateString) => {
        this.setState({
            endDateMoment: date.endOf("day"),
            endTimeValue: "",
            startTimeValue: "",
            //TimeLineへの反映
            end: date.endOf("day"),
            max: date.endOf("day")
        });

        //開始日との兼ね合い
        if (date.isSameOrAfter(this.state.startDateMoment, "day")) {
            //正常
        } else {
            this.setState({
                startDateMoment: date
            })
        }
    };


    checkOneDay = (dateMoment) => {
        //開始日時の選択
        let dateString = dateMoment.format('YYYY-MM-DD');
        let startDateString = this.state.startDateMoment.format('YYYY-MM-DD');

        const presetEndArray = this.state.timePresetData.time_preset_schedule[dateString].scheduled_time_presets;

        //歯抜け連番チェック
        let filteredEndTimes = [];
        //フィルタリングした配列
        const finalFilteredArray = [];
        //生成したセレクト部分の内容
        let end_times_options = [];
        //飛び石ぶぶんがあるかどうかの確認
        let hasStepStone = false;

        this.setState({
            endTimeValue: ""
        });

        //そもそも開始日と同一日かどうかの確認
        if (dateString === startDateString) {
            //選択の前を削除
            filteredEndTimes = presetEndArray.filter((value, index) => {
                //startの時間より古いものをfiltering
                return moment(`${dateString} ${this.state.startTimeValue}`).isSameOrBefore(`${dateString} ${value.time_preset_time_start}`)
            });
            filteredEndTimes.some((value, index) => {
                //条件に応じて後ろを無視
                if (this.state.timePresetData) {
                    if (value.is_open_as_stock) {
                        if (value.is_open_as_schedule) {
                            if (this.state.timePresetData.reserve_time_has_limit) {
                                const diffMinutes = moment(`${dateString} ${value.time_preset_time_end}`).diff(moment(`${dateString} ${this.state.startTimeValue}`), "minutes");
                                if (Number(this.state.timePresetData.reserve_time) < diffMinutes) {
                                    hasStepStone = true;
                                    return true;
                                } else if (Number(this.state.timePresetData.reserve_time) === diffMinutes) {
                                    finalFilteredArray.push(value);
                                    hasStepStone = true;
                                    return true;
                                } else {
                                    finalFilteredArray.push(value);
                                }
                            } else {
                                finalFilteredArray.push(value);
                            }
                        } else {
                            if (!this.state.timePresetData.item_can_skip_disabled_schedule) {
                                hasStepStone = true;
                                return true;
                            }
                        }
                    } else {
                        hasStepStone = true;
                        return true;
                    }
                } else {


                }
            });
        } else {
            //同一なので、開始時間に応じて削る必要はないのでそのまま利用
            filteredEndTimes = presetEndArray;
            filteredEndTimes.some((value, index) => {
                //条件に応じて後ろを無視
                if (this.state.timePresetData) {
                    if (value.is_open_as_stock) {
                        if (value.is_open_as_schedule) {
                            if (this.state.timePresetData.reserve_time_has_limit) {
                                //前の日付の選択部分から00:00分までの差分および 00:00分から先の差分をdiffる必要がある
                                const diffMinutes =
                                    moment(`${dateString} 24:00:00`).diff(moment(`${dateString} ${this.state.startTimeValue}`), "minutes")
                                    +
                                    moment(`${dateString} ${value.time_preset_time_end}`).diff(moment(`${dateString} 00:00:00`), "minutes")
                                ;
                                if (Number(this.state.timePresetData.reserve_time) < diffMinutes) {
                                    hasStepStone = true;
                                    return true;
                                } else if (Number(this.state.timePresetData.reserve_time) === diffMinutes) {
                                    finalFilteredArray.push(value);
                                    hasStepStone = true;
                                    return true;
                                } else {
                                    finalFilteredArray.push(value);
                                }
                            } else {
                                finalFilteredArray.push(value);
                            }
                        } else {
                            if (!this.state.timePresetData.item_can_skip_disabled_schedule) {
                                hasStepStone = true;
                                return true;
                            }
                        }
                    } else {
                        hasStepStone = true;
                        return true;
                    }
                }
                return false;
            });
        }
        end_times_options = finalFilteredArray.map((value, index) => {
            //前の日付の選択部分から00:00分までの差分および 00:00分から先の差分をdiffる必要がある
            let diffMinutes = 0;
            if (dateString === startDateString) {
                diffMinutes = moment(`${dateString} ${value.time_preset_time_end}`).diff(moment(`${dateString} ${this.state.startTimeValue}`), "minutes");
                if(Number(diffMinutes) < 0){
                    diffMinutes = moment(`${dateString} 24:00:00`).diff(moment(`${dateString} ${this.state.startTimeValue}`), "minutes");
                    log.debug(diffMinutes)
                }
            }else{
                diffMinutes =
                    moment(`${dateString} 24:00:00`).diff(moment(`${dateString} ${this.state.startTimeValue}`), "minutes")
                    +
                    moment(`${dateString} ${value.time_preset_time_end}`).diff(moment(`${dateString} 00:00:00`), "minutes")
            };

            let diffMinutesLabel = ""
            if(Number(diffMinutes) >= 60){
                var hour = Math.floor(Number(diffMinutes) / 60);
                var min = Number(diffMinutes) % 60;
                diffMinutesLabel = hour + "時間" + min + "分"
            }else{
                diffMinutesLabel = diffMinutes + "分";
            }
            return <MenuItem key={value.time_preset_time_end}
                             value={value.time_preset_time_end}>{`${value.time_preset_name_end} (${diffMinutesLabel})`}</MenuItem>
        });
        this.setState({
            endTimesOptions: end_times_options
        });
        return hasStepStone;
    };


    checkAcrossDays = (startDateMoment, endDateMoment) => {
        //開始日時の選択
        let startString = startDateMoment.format('YYYY-MM-DD');
        let endString = endDateMoment.format('YYYY-MM-DD');
        for (let dateMoment = moment(startString); dateMoment.isSameOrBefore(endString, "day"); dateMoment = dateMoment.add(1, "days")) {
            const hasStepStone = this.checkOneDay(dateMoment);
            if (hasStepStone) {
                this.setState({
                    endDateMoment: dateMoment.endOf("day")
                });
                break;
            }
        }
    };


    generateEndTimeOptions = () => {
        //開始日時の選択
        let startString = this.state.startDateMoment.format('YYYY-MM-DD');
        let endString = this.state.endDateMoment.format('YYYY-MM-DD');
        if (moment(startString).isSame(endString, "day")) {
            this.checkOneDay(this.state.startDateMoment);
        } else {
            //同一日時じゃない場合
            this.checkAcrossDays(this.state.startDateMoment, this.state.endDateMoment);
        }
    };

    /**
     * カレンダーのstart分disable分
     * @param current
     * @returns {boolean}
     */
    disabledStartDate = (current) => {
        return current < this.state.startSearchDateMoment.startOf('day') || this.state.endSearchDateMoment.endOf("day") < current;
    };

    /**
     * カレンダーのend分disableする
     */
    disabledEndDate = (current) => {
        return current < this.state.startDateMoment.startOf('day') || this.state.endSearchDateMoment.endOf("day") < current;
    };

    /**
     * 開始時間の変更
     * @returns {*}
     */
    changeStartTimePreset(select) {
        this.setState({
            startTimeValue: select.target.value
        }, () => {
            this.generateEndTimeOptions()
        });
    };

    /**
     * 終了時間の変更
     * @returns {*}
     */
    changeEndTimePreset(select) {
        this.setState({
            endTimeValue: select.target.value
        });
    };


    /**
     * 予約の実行
     * @returns {*}
     */
    handleReserve = (e) => {
        e.preventDefault();
        const data = this.state.selectData;
        const accountData = this.props.account;
        let reserve_post_data = {};

        let ticket_datetime_start = `${this.state.startDateMoment.format(`YYYY-MM-DD`)} ${this.state.startTimeValue}`;
        let ticket_datetime_end = `${this.state.endDateMoment.format(`YYYY-MM-DD`)} ${this.state.endTimeValue}`;
        if (data.item.questionnaires.length !== 0) {
            let filteredQuestionnaires = [];
            filteredQuestionnaires = data.item.questionnaires.filter((value) => {
                return this.state.questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`] !== [] && this.state.questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`] !== "";
            });
            const ticket_questionnaire_answers = filteredQuestionnaires.map((value, index) => {
                //それぞれの回答に応じた回答を返答
                switch (value.item_questionnaire_type_id) {
                    case "1":
                        const array = [];
                        array.push(this.state.questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`]);
                        return {
                            item_questionnaire_id: value.item_questionnaire_id,
                            ticket_questionnaire_answer_choices: array
                        };
                    case "2":
                        return {
                            item_questionnaire_id: value.item_questionnaire_id,
                            ticket_questionnaire_answer_choices: this.state.questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`]
                        };
                    case "3":
                        return {
                            item_questionnaire_id: value.item_questionnaire_id,
                            ticket_questionnaire_answer_choices: this.state.questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`]
                        };
                }
            });
            reserve_post_data = {
                account_id: accountData.account_id,
                item_id: data.item.item_id,
                ticket_resource_count: this.state.opening_requiring_count,
                ticket_datetime_end,
                ticket_datetime_start,
                ticket_questionnaire_answers: ticket_questionnaire_answers
            };
        } else {
            //空配列は確実にいるので、なにはなくとも ticket_questionnaire_answers は空配列
            reserve_post_data = {
                account_id: accountData.account_id,
                item_id: data.item.item_id,
                ticket_resource_count: this.state.opening_requiring_count,
                ticket_datetime_end,
                ticket_datetime_start,
                ticket_questionnaire_answers: []
            };
        }
        this.setState({
            loading: true
        });
        const completeData = {
            startDateMoment: this.state.startDateMoment,
            endDateMoment: this.state.endDateMoment,
            startTimeValue: this.state.startTimeValue,
            endTimeValue: this.state.endTimeValue
        };
        const countData = this.state.opening_requiring_count;
        this.props.saveCompleteData(
            completeData, countData
        );
        this.props.reserveTicket(reserve_post_data);
    };


    render() {
        const {translate, account, activeLanguage} = this.props;
        const {activeStep} = this.state;

        let questionnaireComponent = undefined;
        if (this.state.selectData.item.questionnaires && this.state.selectData.item.questionnaires.length !== 0) {
            questionnaireComponent = this.state.selectData.item.questionnaires.map((value, index) => {
                /*
                １が単一選択肢
                ２が複数選択肢
                ３が自由記述
                */
                let formatChoices = undefined;
                switch (value.item_questionnaire_type_id) {
                    case "1":
                        const options_array_multiple = value.choices.map((choicesValue, index) => {
                            return <MenuItem
                                key={index}
                                value={choicesValue.item_questionnaire_choice_id}>{choicesValue.item_questionnaire_choice_title}</MenuItem>
                        })
                        const validators = value.item_questionnaire_required_flag ? ['required'] : [];

                        formatChoices = (
                            <SelectValidator
                                fullWidth
                                id="opening_requiring_count"
                                label={`${translate("questionnaire_choices")} *`}
                                helperText={""}
                                placeholder={translate("reserve_count_placeholder")}
                                margin="normal"
                                variant="outlined"
                                name="opening_requiring_count"
                                validators={validators}
                                errorMessages={[translate("questionnaire_choices_placeholder")]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`]}
                                onChange={this.handleChangeAnswer(`item_questionnaire_id_${value.item_questionnaire_id}`)}
                            >
                                {options_array_multiple}
                            </SelectValidator>
                        );
                        break;
                    case "2":
                        const options_array_single = value.choices.map((choicesValue, index) => {
                            return <MenuItem key={index}
                                             value={choicesValue.item_questionnaire_choice_id}>
                                <Checkbox
                                    checked={this.state.questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`].indexOf(choicesValue.item_questionnaire_choice_id) > -1}/>
                                <ListItemText primary={choicesValue.item_questionnaire_choice_title}/>

                            </MenuItem>
                        });
                        formatChoices = (
                            <SelectValidator
                                fullWidth
                                id="opening_requiring_count"
                                label={`${translate("questionnaire_choices")} *`}
                                helperText={""}
                                placeholder={translate("reserve_count_placeholder")}
                                margin="normal"
                                variant="outlined"
                                name="opening_requiring_count"
                                validators={value.item_questionnaire_required_flag ? ['required'] : []}
                                errorMessages={[translate("questionnaire_choices_placeholder")]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: selected => {
                                        //複数選択時のセレクト部分
                                        let labelArray = value.choices.filter((value, index) => {
                                            return selected.indexOf(value.item_questionnaire_choice_id) > -1;
                                        });
                                        return (
                                            <div className={"select-multi"}>
                                                {labelArray.map(value => (
                                                    <Chip className={"select-chip"}
                                                          key={value.item_questionnaire_choice_id}
                                                          label={value.item_questionnaire_choice_title}/>
                                                ))}
                                            </div>
                                        )
                                    }
                                }}
                                value={this.state.questionnairesAnswerData[`item_questionnaire_id_${value.item_questionnaire_id}`]}
                                onChange={this.handleChangeAnswer(`item_questionnaire_id_${value.item_questionnaire_id}`)}
                            >
                                {options_array_single}
                            </SelectValidator>
                        );
                    case "3":
                        //todo 自由入力アンケートのテスト
                        //TODO 確実に自由入力周りのアンケート修正をしないとコケる可能性があるので注意
                        formatChoices = (
                            <TextValidator
                                onClick={() => this.setState({
                                    isOpenStartDateDialog: true
                                })}
                                className={"full-width"}
                                id="account_id"
                                label={translate("questionnaire_input")}
                                helperText={translate("questionnaire_input_placeholder")}
                                placeholder={translate("questionnaire_input_placeholder")}
                                margin="normal"
                                variant="outlined"
                                name={`item_questionnaire_id_${value.item_questionnaire_id}`}
                                validators={value.item_questionnaire_required_flag ? ['required'] : []}
                                errorMessages={[translate("reserve_search_date_start_placeholder")]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleChangeAnswer(`item_questionnaire_id_${value.item_questionnaire_id}`)}
                                value={this.state.startMoment.format("YYYY-MM-DD")}
                            />
                        );
                        /*
                        formatChoices = (
                            <FormItem
                                {...formItemLayout}
                                label={translate("questionnaire_input")}>
                                {getFieldDecorator(`item_questionnaire_id_${value.item_questionnaire_id}`, {
                                    rules: [
                                        {
                                            required: value.item_questionnaire_required_flag,
                                            message: translate("questionnaire_input_placeholder")
                                        },
                                    ]
                                })(
                                    <Input size="large" placeholder={translate("questionnaire_input_placeholder")}/>
                                )}
                            </FormItem>
                        )
                        */
                        break;
                }

                return (
                    <div key={index}>
                        <Typography variant="subtitle2" color={"textSecondary"}>
                            {value.item_questionnaire_title}
                        </Typography>
                        <Typography variant="body1">
                            {value.item_questionnaire_message}
                        </Typography>
                        {formatChoices}
                    </div>
                )
            });
        }

        //予約数入力用フォーム異常を修正
        const selectBoxes = [];
        const num = Number(this.state.selectData.item.item_resource_count);
        const itemUnitLabel = Helper.isJapanese(activeLanguage.code) ? this.state.selectData.item.item_resource_unit: this.state.selectData.item.item_resource_unit_en;

        for (let i = 0; i < num; i++) {
            selectBoxes.push(<MenuItem key={i} value={i + 1}>{i + 1} {itemUnitLabel}</MenuItem>)
        }


        return (
            <div className={"reserve-input"}>
                <Paper className={"paper wide-paper"}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step title={translate("reserve_count")} className={"step"}>
                            <StepLabel
                                optional=
                                    {
                                        activeStep === 0 ?
                                            <span> </span>
                                            :
                                            <span>{this.state.opening_requiring_count} {itemUnitLabel}</span>
                                    }>
                                {translate("reserve_count")}
                            </StepLabel>
                            {activeStep === 0 ?
                                <StepContent>
                                    <div id={"step0"}/>
                                    <ValidatorForm
                                        onError={errors => log.debug(errors)}
                                        onSubmit={this.getTimePreset}>
                                        <SelectValidator
                                            fullWidth
                                            id="opening_requiring_count"
                                            label={`${translate("reserve_count")} *`}
                                            helperText={""}
                                            placeholder={translate("reserve_count_placeholder")}
                                            margin="normal"
                                            variant="outlined"
                                            name="opening_requiring_count"
                                            validators={['required']}
                                            errorMessages={[translate("reserve_count_placeholder")]}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={this.state.opening_requiring_count}
                                            onChange={this.handleChange("opening_requiring_count")}
                                        >
                                            {selectBoxes}
                                        </SelectValidator>
                                        <div className={"flex-box"}>
                                            <LoadingButton
                                                className={"right-gutter full-width"}
                                                fullWidth={true}
                                                type={"button"}
                                                variant="contained"
                                                onClick={this.props.handleBack}
                                                disabled={this.state.ticketLoading}
                                            >
                                                <Icon className={"right-gutter"}>arrow_back_ios</Icon>
                                                {translate("step_back")}
                                            </LoadingButton>
                                            <LoadingButton
                                                className={"left-gutter full-width"}
                                                fullWidth={true}
                                                variant="contained"
                                                color="primary"
                                                type={"submit"}
                                                loading={this.state.timePresetLoading}
                                            >
                                                {translate("step_next")}
                                                <Icon className={"left-gutter"}>arrow_forward_ios</Icon>
                                            </LoadingButton>
                                        </div>
                                    </ValidatorForm>
                                </StepContent>
                                :
                                undefined
                            }
                        </Step>
                        <Step title={translate("reserve_count")} className={"step"}>
                            <StepLabel
                                optional={
                                    activeStep === 1 ?
                                        <span>{translate("reserve_date_and_time_placeholder")}</span>
                                        :
                                        activeStep >= 2 ?
                                            <span>
                                          {this.state.startDateMoment.format(`YYYY-MM-DD`)} {this.state.startTimeValue}
                                                <br/>
                                                {this.state.endDateMoment.format(`YYYY-MM-DD`)} {this.state.endTimeValue === "00:00:00" ? "24:00:00" : this.state.endTimeValue}
                                            </span>
                                            :
                                            <span>　</span>
                                }>
                                {translate("reserve_date_and_time")}
                            </StepLabel>

                            {(() => {
                                if (activeStep === 1) {
                                    //stepCountが1 利用開始日時の選択
                                    let dateString = this.state.startDateMoment.format('YYYY-MM-DD');
                                    const presetArray = this.state.timePresetData.time_preset_schedule[dateString].scheduled_time_presets;
                                    const start_times_options = presetArray.filter((value, index) => {
                                        return value.is_open_as_stock && value.is_open_as_schedule
                                    }).map((value, index) => {
                                        return <MenuItem key={value.time_preset_id}
                                                         value={value.time_preset_time_start}>{value.time_preset_name_start}</MenuItem>
                                    });
                                    const options = {
                                        locale: "ja",
                                        format: {
                                            minorLabels: {
                                                millisecond: 'SSS',
                                                second: 's',
                                                minute: 'HH:mm',
                                                hour: 'HH:mm',
                                                weekday: 'M/D(dd)',
                                                day: 'M/D(dd)',
                                                week: 'w',
                                                month: 'MMM',
                                                year: 'YYYY'
                                            },
                                            majorLabels: {
                                                millisecond: 'HH:mm:ss',

                                                second: 'M/D HH:mm',
                                                minute: 'M/D(dd)',
                                                hour: 'M/D(dd)',
                                                weekday: 'YYYY年',
                                                day: 'YYYY年M月',
                                                week: 'YYYY年M月',
                                                month: 'YYYY年',
                                                year: ''
                                            }
                                        },
                                        editable: false,
                                        selectable: false,
                                        orientation: "top",
                                        width: '100%',
                                        height: '150px',
                                        stack: false,
                                        showMajorLabels: true,
                                        showCurrentTime: true,
                                        start: this.state.start,               // lower limit of visible range
                                        end: this.state.end,
                                        min: this.state.min,
                                        max: this.state.max,         // upper limit of visible range
                                        zoomMin: 1000000,
                                    };

                                    let unit_label = this.state.selectData.item.item_resource_unit_en;
                                    if(Helper.isJapanese(activeLanguage.code)){
                                        unit_label = this.state.selectData.item.item_resource_unit;
                                    }
                                    const editedItems =
                                        this.state.selectData ?
                                            this.state.selectData.opening_graph.map((value, index) => {
                                                let copyObject = Object.assign({}, value);
                                                copyObject.start = moment(value.start).format();
                                                copyObject.end = moment(value.end).format();
                                                if(copyObject.hasOwnProperty("type")){
                                                    copyObject.content = value.content + unit_label;
                                                }
                                                if (copyObject.count && copyObject.count !== 0 && Number(copyObject.count) >= Number(this.state.opening_requiring_count)) {
                                                    copyObject.className = "positive"
                                                } else {
                                                    copyObject.className = "negative"
                                                }
                                                return copyObject;
                                            })
                                            :
                                            [];
                                    return (
                                        <StepContent>
                                            <div id={"step1"}/>
                                            <Timeline
                                                options={options}
                                                items={editedItems}
                                                rangechangedHandler={this.rangeChangeHandler}
                                            />
                                            <div className={"reserve-form-date"}>
                                                <ValidatorForm
                                                    onError={errors => log.debug(errors)}
                                                    onSubmit={this.handleNext}>
                                                    {this.state.canOverDay ?
                                                        <div>
                                                            <Paper className={"paper-reserve-input"}>
                                                                <Typography variant="subtitle2">
                                                                    {translate("reserve_date_and_time_start")}
                                                                </Typography>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <TextValidator
                                                                            onClick={() => this.setState({
                                                                                isOpenStartDateDialog: true
                                                                            })}
                                                                            className={"full-width"}
                                                                            id="account_id"
                                                                            label={translate("reserve_date_start")}
                                                                            placeholder={translate("reserve_date_start_placeholder")}
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            name="account_id"
                                                                            validators={['required']}
                                                                            errorMessages={[translate("reserve_search_date_start_placeholder")]}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton>
                                                                                            <Icon color="primary">
                                                                                                calendar_today
                                                                                            </Icon>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            value={this.state.startDateMoment.format("YYYY-MM-DD")}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={6}>
                                                                        <SelectValidator
                                                                            fullWidth
                                                                            id="item_type"
                                                                            label={translate("reserve_time_start")}
                                                                            placeholder={translate("reserve_time_start_placeholder")}
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            name="item_type"
                                                                            validators={['required']}
                                                                            errorMessages={[translate("reserve_time_start_placeholder")]}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            value={this.state.startTimeValue}
                                                                            onChange={(value) => {
                                                                                this.changeStartTimePreset(value);
                                                                            }}>
                                                                            {start_times_options}
                                                                        </SelectValidator>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                            <div className={"full-width"}>
                                                                <div className={"icon-wrapper"}>
                                                                    <Icon>arrow_downward</Icon>
                                                                </div>
                                                            </div>
                                                            <Paper className={"paper-reserve-input"}>
                                                                <Typography variant="subtitle2">
                                                                    {translate("reserve_date_and_time_end")}
                                                                </Typography>
                                                                <Grid container spacing={24}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <TextValidator
                                                                            onClick={() => this.setState({
                                                                                isOpenEndDateDialog: true
                                                                            })}
                                                                            className={"full-width"}
                                                                            id="end_date"
                                                                            label={translate("reserve_date_end")}
                                                                            placeholder={translate("reserve_date_end_placeholder")}
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            name="account_id"
                                                                            validators={['required']}
                                                                            errorMessages={[translate("reserve_date_end_placeholder")]}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton>
                                                                                            <Icon color="primary">
                                                                                                calendar_today
                                                                                            </Icon>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                            value={
                                                                                this.state.endDateMoment ?
                                                                                    this.state.endDateMoment.format("YYYY-MM-DD") :
                                                                                    ""
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <SelectValidator
                                                                            fullWidth
                                                                            id="item_type"
                                                                            label={translate("reserve_time_end")}
                                                                            helperText={translate("reserve_time_end_placeholder_short")}
                                                                            placeholder={translate("reserve_time_end_placeholder")}
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            name="end_time_value"
                                                                            validators={['required']}
                                                                            errorMessages={[translate("reserve_time_end_placeholder")]}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            value={this.state.endTimeValue}
                                                                            onChange={(value) => {
                                                                                this.changeEndTimePreset(value);
                                                                            }}>
                                                                            {this.state.endTimesOptions}
                                                                        </SelectValidator>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className={"flex-box"}>
                                                                <TextValidator
                                                                    onClick={() => this.setState({
                                                                        isOpenStartDateDialog: true
                                                                    })}
                                                                    className={"full-width"}
                                                                    id="account_id"
                                                                    label={translate("reserve_date_start")}
                                                                    placeholder={translate("reserve_date_start_placeholder")}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    name="account_id"
                                                                    validators={['required']}
                                                                    errorMessages={[translate("reserve_search_date_start_placeholder")]}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton>
                                                                                    <Icon color="primary">
                                                                                        calendar_today
                                                                                    </Icon>
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                    value={this.state.startDateMoment.format("YYYY-MM-DD")}
                                                                />
                                                            </div>
                                                            <div className={"flex-center"}>
                                                                <Paper className={"paper-reserve-input"} style={{flexGrow:1}}>
                                                                <SelectValidator
                                                                    fullWidth
                                                                    id="item_type"
                                                                    label={translate("reserve_time_start")}
                                                                    placeholder={translate("reserve_time_start_placeholder")}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    name="item_type"
                                                                    validators={['required']}
                                                                    errorMessages={[translate("reserve_time_start_placeholder")]}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={this.state.startTimeValue}
                                                                    onChange={(value) => {
                                                                        this.changeStartTimePreset(value);
                                                                    }}>
                                                                    {start_times_options}
                                                                </SelectValidator>
                                                                </Paper>
                                                                <div className={"icon-wrapper"}>
                                                                    <Icon>arrow_forward</Icon>
                                                                </div>
                                                                <Paper className={"paper-reserve-input"} style={{flexGrow:1}}>
                                                                <SelectValidator
                                                                    fullWidth
                                                                    id="item_type"
                                                                    label={translate("reserve_time_end")}
                                                                    placeholder={translate("reserve_time_end_placeholder")}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    name="end_time_value"
                                                                    validators={['required']}
                                                                    errorMessages={[translate("reserve_time_end_placeholder")]}
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    value={this.state.endTimeValue}
                                                                    onChange={(value) => {
                                                                        this.changeEndTimePreset(value);
                                                                    }}>
                                                                    {this.state.endTimesOptions}
                                                                </SelectValidator>
                                                                </Paper>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className={"inner"}>
                                                        <Typography color={"secondary"} variant="caption" gutterBottom>
                                                            {this.state.timePresetData.opening_button_announcement.split('\n').map((item, key) => {
                                                                return <span key={key}>{item}<br/></span>
                                                            })}
                                                        </Typography>
                                                    </div>

                                                    <div className={"flex-box"}>
                                                        <Button
                                                            className={"right-gutter"}
                                                            fullWidth={true}
                                                            variant="contained"
                                                            onClick={this.handleBack}
                                                        >
                                                            <Icon className={"right-gutter"}>arrow_back_ios</Icon>
                                                            {translate("step_back")}
                                                        </Button>
                                                        <Button
                                                            className={"left-gutter"}
                                                            fullWidth={true}
                                                            variant="contained"
                                                            color="primary"
                                                            type={"submit"}
                                                        >
                                                            {translate("step_next")}
                                                            <Icon className={"left-gutter"}>arrow_forward_ios</Icon>
                                                        </Button>
                                                    </div>
                                                </ValidatorForm>
                                            </div>
                                        </StepContent>
                                    )
                                }
                            })()}
                        </Step>

                        <Step title={translate("reserve_step_3")} className={"step"}>
                            <StepLabel
                                optional={
                                    this.state.stepCount === 2 ?
                                        <span>{translate("reservation_detail_placeholder")}</span>
                                        :
                                        undefined
                                }>
                                <div>{translate("reservation_detail")}</div>
                            </StepLabel>
                            <StepContent>
                                <div id={"step2"}/>
                                <ValidatorForm
                                    onError={errors => log.debug(errors)}
                                    onSubmit={this.handleReserve}>
                                    <div>
                                        <div className={"reserve-subject-cell"}>
                                            <Typography variant="subtitle2" color={"textSecondary"}>
                                                {translate("reserve_user")}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                {this.props.account.payload.account_display_name}
                                            </Typography>
                                        </div>
                                        <div className={"reserve-subject-cell"}>
                                            <Typography variant="subtitle2" color={"textSecondary"}>
                                                {translate("region")}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                {this.props.selectData.item.region.region_name}
                                            </Typography>
                                        </div>
                                        <div className={"reserve-subject-cell"}>
                                            <Typography variant="subtitle2" color={"textSecondary"}>
                                                {translate("area")}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                {this.props.selectData.item.area.area_name}
                                            </Typography>
                                        </div>
                                        <div className={"reserve-subject-cell"}>
                                            <Typography variant="subtitle2" color={"textSecondary"}>
                                                {translate("item")}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                {this.props.selectData.item.item_name}
                                            </Typography>
                                        </div>
                                        <div className={"reserve-subject-cell"}>
                                            <Typography variant="subtitle2" color={"textSecondary"}>
                                                {translate("reserve_count")}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                {this.state.opening_requiring_count}
                                            </Typography>
                                        </div>
                                        <div className={"reserve-subject-cell"}>
                                            <Typography variant="subtitle2" color={"textSecondary"}>
                                                {translate("reserve_date_and_time")}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                {this.state.startDateMoment.format(`YYYY-MM-DD`)} {this.state.startTimeValue} ~ {this.state.endDateMoment.format(`YYYY-MM-DD`)} {this.state.endTimeValue === "00:00:00" ? "24:00:00" : this.state.endTimeValue}
                                            </Typography>
                                        </div>
                                        {this.state.selectData.item.questionnaires && this.state.selectData.item.questionnaires.length !== 0 ?
                                            <Paper className={"paper-reserve-input"}>
                                                {questionnaireComponent}
                                            </Paper>
                                            :
                                            <span/>
                                        }
                                    </div>

                                    <div className={"flex-box"}>
                                        <LoadingButton
                                            className={"right-gutter full-width"}
                                            fullWidth={true}
                                            type={"button"}
                                            variant="contained"
                                            onClick={() => this.handleBack()}
                                            disabled={this.state.ticketLoading}
                                        >
                                            <Icon className={"right-gutter"}>arrow_back_ios</Icon>
                                            {translate("step_back")}
                                        </LoadingButton>
                                        <LoadingButton
                                            className={"left-gutter full-width"}
                                            fullWidth={true}
                                            variant="contained"
                                            color="primary"
                                            type={"submit"}
                                            loading={this.state.ticketLoading}
                                        >
                                            {translate("above_mentioned_step")}
                                        </LoadingButton>
                                    </div>
                                </ValidatorForm>
                            </StepContent>
                        </Step>
                    </Stepper>
                </Paper>
                <DatePickerDialog
                    disabledDate={this.disabledStartDate}
                    selectedDate={this.state.startDateMoment}
                    open={this.state.isOpenStartDateDialog}
                    handleClose={() => {
                        this.setState({
                            isOpenStartDateDialog: false
                        })
                    }}
                    handleAction={(date) => {
                        this.onChangeStartDate(date, date.format("YYYY-MM-DD"))
                        this.setState({
                            isOpenStartDateDialog: false
                        })
                    }}
                />
                <DatePickerDialog
                    disabledDate={this.disabledEndDate}
                    selectedDate={this.state.endDateMoment}
                    open={this.state.isOpenEndDateDialog}
                    handleClose={() => {
                        this.setState({
                            isOpenEndDateDialog: false
                        })
                    }}
                    handleAction={(date) => {
                        this.setState({
                            isOpenEndDateDialog: false
                        })
                        this.onChangeEndDate(date, date.format("YYYY-MM-DD"));
                    }}
                />
            </div>
        );
    }
}


ReserveInput.propTypes = {
    handleBack: PropTypes.func,
    handleNext: PropTypes.func
};

ReserveInput.defaultProps = {
    handleBack: () => {

    },
    handleNext: () => {

    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(ReserveInput)));