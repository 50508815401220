export const randomRange = (min,max) => {
    return Math.floor(Math.random() * (max - min +1) + min);
};


export const isJapanese = (value) => {
    return value === "jp"
};

export const universityCodeEnum = {
    TEZUKA: "TEZUKAYAMA",
    OTEMON: "OTEMON",
    OTHERS: "SIBA-SERVICE"
};
export const getUniversityCodeFromDomain = () => {
    const hostname = window.location.hostname;
    switch(hostname) {
        case "tu.ist-reserve.space":
            return universityCodeEnum.TEZUKA;
        case "ors.ist-reserve.space":
            return universityCodeEnum.OTEMON;
        default :
            return universityCodeEnum.OTHERS;
    }
};