import React, {Component} from 'react';
import {withLocalize, Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import {Button, Icon, Paper, Typography} from '@material-ui/core'
import log from 'utils/logger';
import Header from "../header/Header";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import LoadingButton from "../../utils/LoadingButton";
import * as Actions from "../../redux/actions";
import connect from "react-redux/es/connect/connect";
import {Link, withRouter} from "react-router-dom";
import * as ReactDOM from "react-dom";
import IntervalTimer from "react-interval-timer";
import * as Helper from "../../utils/Helper";
import {universityCodeEnum} from "../../utils/Helper";

const mapStateToProps = (state, props) => {
    return {
        settings: state.settings,
        account: state.account,
        target: state.target
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doLogout() {
            dispatch(Actions.http.connection.authentication.logout());
        }
    }
};


class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: props.settings,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.settings !== this.props.settings) {
            this.setState({
                settings: this.props.settings
            })
        }
    }

    render() {
        const {languages, activeLanguage, setActiveLanguage, target, translate, account, history} = this.props;

        const univCode = Helper.getUniversityCodeFromDomain();

        return (
            <div className="App">
                <Header
                    showLanguageChangeButton={true}
                    showLogoutButton={true}
                    showMenuButton={false}
                />
                <div className={"Container"}>
                    <div className={"main-menu"}>

                        <div className={"logo-info-wrapper"}>
                            <Paper elevation={1} className={"paper-logo"}>
                                <div className={"logo-wrapper"}>
                                    <div className={"logo-image"}/>

                                    <div className={"name-wrapper"}>
                                        <Icon className={"icon"} color="primary">
                                            supervisor_account
                                        </Icon>
                                        <Typography className={"gutter-bottom"} variant={"h6"} align="center">
                                            {account.payload.account_user_id} {account.payload.account_display_name} {account.payload.account_display_name_prefix}
                                        </Typography>
                                    </div>
                                </div>

                                <div>

                                </div>
                            </Paper>


                            <Paper className={"paper-information"}>
                                <div>
                                    <Typography className={"gutter-bottom"} variant={"h6"} align="center">
                                        {translate("information")}
                                    </Typography>
                                    <br/>
                                    <Typography component={"p"} align={"left"}>
                                        {activeLanguage.code === "jp" ?
                                            this.state.settings.payload.menu_message
                                            :
                                            this.state.settings.payload.menu_message_en
                                        }
                                    </Typography>
                                </div>
                            </Paper>
                        </div>

                        <div className={"menu-wrapper"}>
                            {this.props.target.payload === "checkin" && univCode !== universityCodeEnum.TEZUKA ?
                                <div className={"menu-check-in"}>
                                    <Paper className={"check-in-button button"}
                                           onClick={() => {
                                               history.push("/checkin")
                                           }}
                                    >
                                        <div className={"icon sit"}>
                                        </div>
                                        <div className={"title"}>
                                            {translate("checkin")}
                                        </div>
                                    </Paper>

                                    <Paper className={"check-out-button button"}
                                           onClick={() => {
                                               history.push("/checkout")
                                           }}
                                    >
                                        <div className={"icon stand"}>
                                        </div>
                                        <div className={"title"}>
                                            {translate("checkout")}
                                        </div>
                                    </Paper>
                                </div>
                                : undefined
                            }
                            <div className={"menu-common"}>
                                {Number(this.props.account.payload.account_user_type_id) <= 10 && univCode === universityCodeEnum.TEZUKA ?
                                    null
                                    :
                                    <Paper className={"common-button button"} onClick={() => {
                                        history.push("/reserve")
                                    }}>
                                        <Icon className={"icon"}>
                                            today
                                        </Icon>
                                        <div className={"title"}>
                                            {translate("menu_reserve")}
                                        </div>
                                    </Paper>
                                }
                                {this.props.settings.payload.use_map_reservation ?
                                    <Paper className={"common-button button"}>
                                        <Icon className={"icon"}>
                                            map
                                        </Icon>
                                        <div className={"title"}>
                                            {translate("menu_map_reserve")}
                                        </div>
                                    </Paper>

                                    :
                                    undefined
                                }
                                <Paper className={"common-button button"} onClick={() => {
                                    history.push("/cancel")
                                }}>
                                    <Icon className={"icon"}>
                                        assignment
                                    </Icon>
                                    <div className={"title"}>
                                        {translate("menu_reserve_check_cancel")}
                                    </div>
                                </Paper>

                                <Paper
                                    className={"common-button button"}
                                    onClick={() => {
                                        if (target.payload === "checkin") {
                                            log.debug(target.payload)
                                            history.push("/change-passcode-checkin")
                                        } else {
                                            log.debug(target.payload)
                                            history.push("/change-passcode")
                                        }
                                    }
                                    }>
                                    <Icon className={"icon"}>
                                        https
                                    </Icon>
                                    <div className={"title"}>
                                        {translate("menu_change_passcode")}
                                    </div>
                                </Paper>

                            </div>
                        </div>
                    </div>
                </div>


                <IntervalTimer
                    timeout={this.props.settings.payload.timeout_time_menu ? this.props.settings.payload.timeout_time_menu * 1000 : 60000}
                    callback={this.props.doLogout}
                    enabled={this.props.target.payload === "checkin"}
                    repeat={false}
                />
            </div>
        )
    }
}


MainMenu.propTypes = {};

MainMenu.defaultProps = {};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(MainMenu)));