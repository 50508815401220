
// バージョン情報
// カスタムイメージ
export const VersionName = "IstReserve";
export const TENANT_KEY = "teikyo";
// サービス名称
export const LoginTitle ="KnowledgeIstReserve";
export const VERSION_CODE = "KnowledgeIstReserve 3.0.0.1 2019/03/01 Release";
export const COPYRIGHT = "Copyright © SibaService All rights reserved."


export const optimizedEvent = window.ontouchstart===null?"touchstart":"click";
export const optimizedButtonSize = (target) => {
    if (target === "checkin") {
        return "large"
    } else {
        return "default"
    }
}

