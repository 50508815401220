import React, {Component} from 'react';
import { withLocalize, Translate } from 'react-localize-redux';

class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate : new Date()
        };
    }

    render() {
        return (
            <div className="App">
                <Translate id={"search"} />
            </div>
        );
    }
}

export default withLocalize(Test);
