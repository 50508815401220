import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize, Translate} from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {Icon, Paper} from "@material-ui/core";
import log from "utils/logger";
import connect from "react-redux/es/connect/connect";
import * as Actions from "redux/actions";
import Header from "containers/header/Header";
import Ticket from 'containers/ticket/Ticket';
import NumberPickerDialog from "../../utils/NumberPickerDialog";
import NoContent from "../nocontent/NoContent";
import LoadingOverlay from "react-loading-overlay";

const mapStateToProps = (state, props) => {
    return {
        session: state.session,
        target: state.target,
        tickets: state.tickets,
        lockerId: state.lockerId,
        ticketConnection: state.ticketConnection,
        ticketCheckInConnection: state.ticketCheckInConnection,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        checkinTicket: (ticket_id, ticket_user_count) => {
            dispatch(Actions.http.connection.tickets.checkin(ticket_id, ticket_user_count))
        },
        getTicketList: () => {
            const query = {
                type: "checkin"
            };
            dispatch(Actions.http.connection.tickets.get(query))
        },
        resetTickets: () => {
            dispatch(Actions.data.tickets([]));
        }
    }
};

class CheckIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets: [],
            loading: true,
            isOpenNumberPickerDialog: false,
            selectTicket: {},
            isComplete: false,
            ticketCheckInConnection: props.ticketCheckInConnection
        };
        props.getTicketList();
    }

    static getDerivedStateFromProps(nextProps, state) {
        let value = {};
        if (nextProps.tickets.payload !== state.tickets) {
            value.tickets = nextProps.tickets.payload;
        }
        if (nextProps.ticketCheckInConnection !== state.ticketCheckInConnection) {
            value.ticketCheckInConnection = nextProps.ticketCheckInConnection;
            if (nextProps.ticketCheckInConnection.meta.status === Actions.statusEnum.SUCCESS) {
                value.printObject = nextProps.ticketCheckInConnection.payload.result.ticket.print_data;
                window.printObject = value.printObject;
                document.location.href = 'ticketprint://ticketprint';
                nextProps.history.push("/checkin-complete");
            }
        }
        value.loading = nextProps.ticketConnection.meta.fetch || nextProps.ticketCheckInConnection.meta.fetch;
        if (0 === Object.keys(value).length) {
            return null;
        }
        return value;
    }

    componentWillUnmount(): void {
        this.props.resetTickets();
    }

    open = (selectTicket) => {
        this.setState({
            isOpenNumberPickerDialog: true,
            selectTicket
        })
    };


    render() {
        const {translate, openings} = this.props;

        return (
            <div className="App">
                <Header
                    title={translate("checkin")}
                    showMenuButton={true}
                    showLanguageChangeButton={false}
                />
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    style={{
                        wrapper:{
                            overflow: this.props.active ? 'hidden' : 'scroll'
                        }
                    }}
                >
                <div className={"Container"}>
                    {this.state.tickets.length === 0 ?
                        this.state.loading ?
                            <span/>
                            :
                            <NoContent
                                actionButtonLabel={this.props.translate("back_to_menu")}
                            />
                        :
                        <div className={"ticket-list"}>
                            {
                                this.state.tickets.map((value, index) => {
                                    return <Ticket type={"checkin"}
                                                   key={index}
                                                   value={value}
                                                   callback={this.open}
                                                   target={this.props.target.payload}/>
                                })
                            }
                        </div>
                    }
                </div>
                </LoadingOverlay>
                <NumberPickerDialog
                    submitTitle={translate("checkin")}
                    open={this.state.isOpenNumberPickerDialog}
                    handleClose={() => {
                        this.setState({
                            isOpenNumberPickerDialog: false
                        })
                    }}
                    handleAction={(value) => {
                        this.setState({
                            isOpenNumberPickerDialog: false
                        })
                        this.props.checkinTicket(this.state.selectTicket.ticket_id, value)
                    }}
                    maxCount={this.state.selectTicket !== {} ? this.state.selectTicket.item_user_max_count_has_limit ? this.state.selectTicket.item_user_max_count : 999 : 1}
                />

            </div>
        );
    }
}


export default withRouter(withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckIn)));