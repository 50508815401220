import React, {Component} from 'react';

import {connect, store} from 'react-redux'
import {withRouter} from "react-router-dom";
import {withLocalize, Translate} from 'react-localize-redux';
import Header from "../header/Header";
import {Button, Paper, Typography, Icon} from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import log from 'utils/logger'
import * as Actions from "../../redux/actions";

import {ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import LoadingButton from "../../utils/LoadingButton";
import MenuItem from "@material-ui/core/MenuItem";
import * as GlobalConfig from "../../constants/GlobalConfig";

const mapStateToProps = (state, props) => {
    return {
        tenants: state.tenants,
        settings: state.settings,
        loginConnection: state.loginConnection
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doLogin(values) {
            dispatch(Actions.http.connection.authentication.login(values));
        },
        doLoginBackdoor: () => {
            dispatch(Actions.http.connection.authentication.backdoor());
        },
        checkLoggedIn: () => {
            dispatch(Actions.http.connection.bundles());
        }
    }
};

class Login extends Component {
    constructor(props) {
        super(props);

        const params = new URLSearchParams(props.location.search);
        const isCheckinMode = params.get('checkinmode') ? params.get('checkinmode') : false;
        let account_id = "";
        let tenant_id = "";
        let accountPattern = new RegExp(/^[a-z0-9A-Z]+$/);
        if (params.get("ac") && accountPattern.test(params.get("ac"))) {
            account_id = params.get("ac");
        }
        let tenantPattern = new RegExp(/^[0-9]+$/);
        if (params.get("tn") && tenantPattern.test(params.get("tn"))) {
            tenant_id = params.get("tn");
        }

        this.state = {
            loading: false,
            isOpen: false,
            passcode: "",
            loginConnection: props.loginConnection,
            settings: props.settings,
            isCheckinMode,
            account_id,
            tenant_id
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.session !== this.props.session) {
            this.setState({
                session: this.props.session
            })
        }
        if (prevProps.loginConnection !== this.props.loginConnection) {
            this.setState({
                loginConnection: this.props.loginConnection
            })
        }
        if (prevProps.settings !== this.props.settings) {
            this.setState({
                settings: this.props.settings
            })
        }
    }

    handleOpen = () => {
        this.setState({
            isOpen: true
        })
    };

    handleClose = () => {
        this.setState({
            isOpen: false
        })
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleLogin = (e) => {
        e.preventDefault();
        const values = {
            account_user_id: this.state.account_id,
            account_passcode: this.state.passcode,
            tenant_id: this.state.tenant_id
        }
        this.props.doLogin(values);
    };


    static getDerivedStateFromProps(nextProps, state) {
        return {
            loading: nextProps.loginConnection.meta.fetch
        }
    }


    render() {
        const {languages, activeLanguage, setActiveLanguage, translate} = this.props;
        return (
            <div className="App">
                <Header
                    showLanguageChangeButton={true}
                    showLogoutButton={this.state.isCheckinMode}
                    showMenuButton={false}
                />
                <div className={"Container with-footer"}>
                    <div className={"Login"}>
                        <Paper className={"paper-login"} elevation={1}>
                            <div className={"logo-wrapper"}>
                                <div className={"logo-image"}/>
                            </div>
                            <ValidatorForm
                                onError={errors => log.debug(errors)}
                                onSubmit={this.handleLogin}>
                                <TextValidator
                                    fullWidth
                                    inputProps={{
                                        autoCapitalize: 'none',
                                    }}
                                    id="account_id"
                                    label={translate("account_id")}
                                    helperText={this.state.idHelper}
                                    placeholder={translate("account_id_placeholder")}
                                    margin="normal"
                                    variant="outlined"
                                    name="account_id"
                                    validators={['required']}
                                    errorMessages={[translate("account_id_placeholder")]}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.account_id}
                                    onChange={this.handleChange("account_id")}
                                />
                                <TextValidator
                                    fullWidth
                                    id="passcode"
                                    label={translate("passcode")}
                                    helperText={this.state.passwordHelper}
                                    placeholder={translate("passcode_placeholder")}
                                    margin="normal"
                                    variant="outlined"
                                    type="password"
                                    name="passcode"
                                    validators={['required']}
                                    errorMessages={[translate("passcode_placeholder")]}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.passcode}
                                    onChange={this.handleChange("passcode")}
                                />
                                {this.props.settings.payload.use_tenant_code ?
                                    <SelectValidator
                                        fullWidth
                                        inputProps={{
                                            autoCapitalize: 'none',
                                        }}
                                        id="item_type"
                                        label={translate("tenant")}
                                        helperText={translate("tenant_placeholder")}
                                        placeholder={translate("tenant_placeholder")}
                                        margin="normal"
                                        variant="outlined"
                                        name="tenant_id"
                                        validators={['required']}
                                        errorMessages={[translate("tenant_placeholder")]}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={this.state.tenant_id}
                                        onChange={this.handleChange("tenant_id")}
                                    >
                                        {(() => {
                                            return this.props.tenants.payload.map((value, index) => {
                                                if (this.props.activeLanguage.code === "jp") {
                                                    return <MenuItem key={value.tenant_id}
                                                                     value={value.tenant_id}>{value.tenant_name}</MenuItem>
                                                } else {
                                                    return <MenuItem key={value.tenant_id}
                                                                     value={value.tenant_id}>{value.tenant_name}</MenuItem>
                                                }
                                            });
                                        })()}
                                    </SelectValidator>
                                    :
                                    null
                                }
                                <div className={"help-text"}>
                                    <Typography component="p" align={"left"}
                                                children={
                                                    <span className={"selectable"} onClick={this.handleOpen}>
                                            <Icon fontSize={"inherit"}>live_help</Icon>
                                            <Translate id="login_help"/>
                                        </span>
                                                }
                                    />
                                </div>
                                <LoadingButton
                                    fullWidth={true}
                                    variant={"contained"}
                                    type={"submit"}
                                    color={"primary"}
                                    loading={this.state.loading}
                                >
                                    {translate("login")}
                                </LoadingButton>
                                {/*
                                    <LoadingButton
                                        fullWidth={true}
                                        variant={"contained"}
                                        onClick={this.props.doLoginBackdoor}
                                        color={"primary"}
                                        loading={this.state.loading}>
                                        {translate("login")}
                                    </LoadingButton>
                                */}
                            </ValidatorForm>
                        </Paper>
                        <Paper className={"paper-information"}>
                            <Typography className={"gutter-bottom"} variant={"h6"} align="center">
                                {translate("information")}
                            </Typography>
                            <br/>
                            <Typography component={"p"} align={"left"}>
                                {activeLanguage.code === "jp" ?
                                    this.state.settings.payload.login_message
                                    :
                                    this.state.settings.payload.login_message_en
                                }
                            </Typography>
                        </Paper>
                        <Dialog
                            fullScreen={false}
                            open={this.state.isOpen}
                            onClose={this.handleClose}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">
                                <Translate id="login_help"/>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {translate("login_help_message_1")}
                                    {translate("login_help_message_2")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className={"login-help-actions"}>
                                <Button color={"primary"} onClick={this.handleClose}
                                        variant={"contained"} autoFocus>
                                    {translate("close")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
                <div className={"Footer"}>
                    <span>
                        {GlobalConfig.VERSION_CODE}
                        <br/>
                        {GlobalConfig.COPYRIGHT}
                    </span>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(Login)))
