import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import log from 'utils/logger';
import {withLocalize} from "react-localize-redux";
import {Icon} from "@material-ui/core";
import * as Helper from "./Helper";


class ReserveStatusButtonSet extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {translate, activeLanguage, history} = this.props;
        let reconfirmFooter = <div/>;
        if (this.props.value.ticket_cancel === true) {
            reconfirmFooter = (
                <div className={"detail-button"}>
                    <Button disabled={true}
                            color={"secondary"}
                            variant={"contained"}
                            onClick={() => {
                                this.props.callback(this.props.value)
                            }}>
                        {translate("cancel_confirmed")}
                    </Button>
                </div>
            )
        } else if (this.props.value.ticket_checkout === true) {
            if (this.props.target === "checkin") {
                reconfirmFooter = (
                    <div className={"detail-button"}>
                        <Button disabled={true} className={"detail-button"}
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => {
                                    this.props.callback(this.props.value)
                                }}>
                            {translate("checkout_confirmed")}
                        </Button>
                    </div>
                )
            } else {
                reconfirmFooter = (
                    <div className={"detail-button"}>
                        <Button disabled={true} className={"detail-button"}
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => {
                                    this.props.callback(this.props.value)
                                }}>
                            {translate("checkout_confirmed")}
                        </Button>
                    </div>
                )
            }
        } else if (this.props.value.ticket_checkin === true) {
            if (this.props.target === "checkin") {
                switch (this.props.type) {
                    case "checkout":
                        reconfirmFooter = (

                            <div className={"detail-button"}>
                                <Button disabled={false} className={"detail-button"}
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={() => {
                                            this.props.callback(this.props.value)
                                        }}>
                                    {translate("checkout")}
                                </Button>
                            </div>
                        )
                        break;
                    default:
                        reconfirmFooter = (

                            <div className={"detail-button"}>
                                <Button disabled={false} className={"detail-button"}
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={() => {
                                            this.props.print(this.props.value)
                                        }}>
                                    {translate("re_print")}
                                </Button>
                                <Button disabled={true} className={"detail-button"}
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={() => {
                                            this.props.callback(this.props.value)
                                        }}>
                                    {translate("checkin_confirmed")}
                                </Button>
                            </div>
                        )
                        break;
                }

            } else {

                reconfirmFooter = (

                    <div className={"detail-button"}>
                        <Button disabled={true} className={"detail-button"}
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => {
                                    this.props.callback(this.props.value)
                                }}>
                            {translate("checkin_confirmed")}
                        </Button>
                    </div>
                )
            }
        } else {
            switch (this.props.type) {
                case "checkin":
                    reconfirmFooter = (
                        <div className={"detail-button"}>
                            <Button disabled={!this.props.value.ticket_can_checkin} className={"detail-button"}
                                    color={"primary"}
                                    variant={"contained"}
                                    onClick={() => {
                                        this.props.callback(this.props.value)
                                    }}>
                                {translate("checkin")}
                            </Button>
                        </div>
                    )
                    break;

                case "checkout":
                    reconfirmFooter = (
                        <div className={"detail-button"}>
                            <Button disabled={false} className={"detail-button"}
                                    color={"primary"}
                                    variant={"contained"}
                                    onClick={() => {
                                        this.props.callback(this.props.value)
                                    }}>
                                {translate("checkout")}
                            </Button>
                        </div>
                    )
                    break;
                case "cancel":
                    reconfirmFooter = (
                        <div className={"detail-button"}>
                            <Button disabled={false} className={"detail-button"}
                                    color={"primary"}
                                    variant={"contained"}
                                    onClick={() => {
                                        this.props.callback(this.props.value)
                                    }}>
                                {translate("cancel")}
                            </Button>
                        </div>
                    )
                    break;
                default :
                    reconfirmFooter = (<div/>)
                    break;
            }
        }
        return (reconfirmFooter);
    }
}

ReserveStatusButtonSet.defaultProps = {
    value: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    print: PropTypes.func,
    callback: PropTypes.func.isRequired
};

ReserveStatusButtonSet.propTypes = {
    value: undefined,
    callback: () => {
    },
    type: "view",
    print: () => {
    },
    target: "client"
};

export default withLocalize(ReserveStatusButtonSet);